<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4" order-sm="1">
				<v-subheader>Nuevo artículo</v-subheader>
				<v-divider class="mb-2"></v-divider>
        <NuevoArticulo
          @reload="$emit('reload')"
          :key="keyHelper"
          :value="articulo"
        />
      </v-col>
      <v-col cols="12" md="8" order-sm="1">
        <v-subheader>Listado artículos</v-subheader>
        <v-divider class="mb-2"></v-divider>
        <v-data-table
          fixed-header
          :items="articulos.filter(a => !loading)"
          :headers="articulosHeader"
          class="fixed-checkbox"
          item-key="idArticulo"
          :single-select="false"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [0, -1],
          }"
          checkbox-color="secondary"
        >
          <template v-slot:body.prepend="{ headers }">
            <table-filters
              :headers="headers"
              :items="articulos"
              v-model="inlineFilters"
            ></table-filters>
          </template>
          <template v-slot:item.n="{ item, index }">
            <td style="white-space: nowrap !important;padding-left: 22px !important;">{{
            index+1 }}</td>
          </template>
          <template v-slot:item.actualizado="{ item }">
            {{ new Date(new Date(item.actualizado).setMonth(item.actualizado.getMonth()+1)).toLocaleDateString('esp', { day : 'numeric', month : 'short', year : 'numeric' }).toLocaleUpperCase() }}
          </template>
          <template v-slot:item.acciones="{ item }">
            <div class="d-flex">
              <v-btn
                @click="articulo = item"
                small
                rounded
                color="secondary"
              >
                <v-icon left>mdi-pencil</v-icon>
                Editar
              </v-btn>
              <v-btn
                @click="deleteArticulo(item)"
                small
                class="ml-1"
                rounded
                color="error"
              >
                <v-icon left>mdi-delete</v-icon>
                Eliminar
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
      
    </v-row>
		
		<ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { parseDate, perColumnFilter } from "@/utils/index.js";

export default {
  props: {
    loading: Boolean,
    familias: Array,
    articulos: Array
  },
  components: {
    NuevoArticulo: () => import("./NuevoArticulo.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      articulo: null,
      inlineFilters: {},
      keyHelper: 0,
      articulosHeader: [
        { text: "Nº", value: "n", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Nombre", value: "nombre", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Precio", value: "precio", cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Nota", value: "nota", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Actualizado", value: "actualizado", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Acciones", value: "acciones", sortable: false, filterable: false },
      ].map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-1 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),
    };
  },
  watch: {
    articulo() {
      this.keyHelper++;
    },
  },
  methods: {
    parseDate,
    async deleteArticulo({ idArticulo, nombre }) {
			const confirmation = await this.$refs.confirm.open(
				`Artículo: ${nombre}`,
        `ATENCIÓN: Todos los escandallos asociados a este artículo se elminarán, junto a las recetas que contengan esos escandallos, y los menús que contengan esas recetas. ESTA ACCIÓN ES IRREVERSIBLE SIN UNA COPIA DE SEGURIDAD. ¿Seguro que quieres eliminar el artículo?`,
        { color: "error" }
      );
      if (!confirmation) return;

      const { data } = await axios({
        method: "DELETE",
        url: "/articulos/" + idArticulo,
      });
      this.$emit("reload")
    },
    async getArticulos(){
      this.keyHelper++;
      const { data } = await axios({
        url: "/articulos",
      });
      this.articulos = data;
      this.articulos.forEach(art => {
        art.actualizado = new Date(art.actualizado)
      });
    },
  }
};
</script>